
import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { formatDate } from "@/utils/formatters";
const groupBy = require("lodash/groupBy");
import { HasFormErrors } from "@/mixins/has-form-errors";
import { PartnerRequest } from "@/store/modules/partner-request.store";

import type { indexTimelinePayload } from "@/store/modules/timeline.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        TimelineEntryCard: () => import("@/components/timeline/entry-card.vue"),
        TimelineCreateEntry: () => import("@/components/timeline/create-entry.vue"),
        EditTimelineEntryModal: () => import("@/components/modals/edit-timeline-entry.vue"),
    },
})
export default class PagePartnerRequestNotes extends Mixins(HasFormErrors) {
    @Getter("timeline/notes") timelineNotes!: TimelineItem[];
    @Action("timeline/index") indexTimeline!: (payload: indexTimelinePayload) => Promise<TimelineItem[]>;

    @Prop({ required: true }) partnerRequest!: PartnerRequest;

    loading = false;

    get entries() {
        return this.timelineNotes;
    }

    get timelineItemsByDate(): { [key: string]: TimelineItem[] } {
        if (!this.entries) {
            return {};
        }

        const day = (entry: TimelineItem) => formatDate(entry.updated_at, "dddd D MMMM");

        return groupBy(this.entries, day);
    }

    async mounted() {
        this.loading = true;

        await this.queryTimeline();

        this.loading = false;
    }

    async queryTimeline() {
        return await this.indexTimeline({ q: { and: { activity_id: Number(this.partnerRequest.activity.id) } }, per_page: 900, s: { created_at: "desc" } });
    }
}
